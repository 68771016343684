import { createTheme } from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily:  "'Montserrat', sans-serif",
        color: 'white',
        h1: {
            fontSize: 120
        },
        h2: {
            fontSize: 64,
            fontWeight: 800,
            textTransform:'uppercase',
            lineHeight: 1,
            marginBottom: 100
        },
        h4: {
            fontSize: 27
        },
        h6: {
            fontWeight: 600,
            textTransform:'uppercase',
        },
        subtitle1: {
            fontWeight: 800,
            fontSize: 20,
            textTransform:'uppercase',
        },
        caption: {
            fontWeight: 800,
            fontSize: 14,
            textTransform:'uppercase',
        },
        body1: {
            fontSize: 24,
            fontWeight: 400,
        },
        body2: {
            fontSize: 16,
            fontWeight: 400,
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#7e0001',
            light: '#b30000',
        },
        secondary: {
            main: '#1b1b1b',
        },
        background: {
            default: '#1b1b1b', 
            paper: '#1b1b1b',
        },
        text: {
            primary: '#fff',
            main: '#fff',
            secondary: '#8f8f8f',
        },
        grey: {
            main: '#5f5f5f',
        },
    },
    zIndex: {
        appBar: 1200,
        drawer: 1100,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    '& ::-webkit-scrollbar': {
                        width: '14px',
                    },
                    '& ::-webkit-scrollbar-track': {
                        boxShadow: "inset 0 0 5px #1b1b1b; ",
                        borderRadius: "10px"
                    },
                    '& ::-webkit-scrollbar-thumb': {
                        background: "#7e0001",
                        borderRadius: "3px"
                    },
                    '& ::-webkit-scrollbar-thumb:hover': {
                        background: "#b30000",
                    },
                    '& ::selection': {
                        backgroundColor: '#7e0001',
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1b1b1b',
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '0.75rem 1rem',
                    borderRadius: '.375rem'
                },
            },
        },
    },
});

export default theme;