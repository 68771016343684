import { ArrowBackRounded, ArrowForwardRounded, CloseRounded } from "@mui/icons-material";
import { AppBar, Box, CircularProgress, Grid, IconButton, Modal, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const AllPhotos = ({ images }) => {
    const [displayedImages, setDisplayedImages] = useState([]);
    const [zoomedImage, setZoomedImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingZoomed, setLoadingZoomed] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);

    console.log(displayedImages);

    const handleClose = () => {
        window.history.back();
    };

    
    const handleImageLoad = () => {
        setLoadingZoomed(false);
    };

    const handleNext = () => {
        if (currentIndex === displayedImages.length - 1) {
            setCurrentIndex(0);
            setZoomedImage(displayedImages[0].src);
            setLoadingZoomed(true); // Reset loader
            return;
        }

        setCurrentIndex(currentIndex + 1);
        setZoomedImage(displayedImages[currentIndex + 1].src);
        setLoadingZoomed(true); // Reset loader
    };

    const handlePrevious = () => {
        if (currentIndex === 0) {
            setCurrentIndex(displayedImages.length - 1);
            setZoomedImage(displayedImages[displayedImages.length - 1].src);
            setLoadingZoomed(true); // Reset loader
            return;
        }

        setCurrentIndex(currentIndex - 1);
        setZoomedImage(displayedImages[currentIndex - 1].src);
        setLoadingZoomed(true); // Reset loader

    };

    // use keyboad arrow keys to navigate
    const handleKeyDown = (event) => {
        if (event.key === "ArrowRight") {
            handleNext();
        } else if (event.key === "ArrowLeft") {
            handlePrevious();
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    });


    

    useEffect(() => {

        // Fetch images from images
        const fetchImages = async () => {

            const processImage = (image) => {
                return new Promise((resolve) => {
                    const img = new Image();

                    img.onload = () => {
                        resolve({
                            src: image,
                            isPortrait: img.naturalHeight > img.naturalWidth,
                        });
                    };

                    img.src = image;
                });
            };

            const allImages = await Promise.all(images.map(processImage));

            // be sure that there are no portrait images in the last row
            let lastRow = 0;
            for (let i = 0; i < allImages.length; i++) {
                if (allImages[i].isPortrait) {
                    if (i - lastRow < 2) {
                        let j = i + 1;
                        while (j < allImages.length) {
                            if (!allImages[j].isPortrait) {
                                const temp = allImages[i];
                                allImages[i] = allImages[j];
                                allImages[j] = temp;
                                break;
                            }
                            j++;
                        }
                    }
                }
                if (i % 3 === 2) {
                    lastRow = i;
                }
            }

            
            
            const portraitImages = allImages.filter(image => image.isPortrait);
            const landscapeImages = allImages.filter(image => !image.isPortrait);

            // Calculate how many landscape images are needed for the last row
            const requiredLandscapeCount = 4 - (allImages.length % 4);
            const usableLandscapeImages = landscapeImages.slice(0, requiredLandscapeCount);

            // Final images array ensuring that the last row has no portrait images
            const finalImages = [
                ...landscapeImages.slice(0, landscapeImages.length - requiredLandscapeCount),
                ...portraitImages,
                ...usableLandscapeImages,
            ];


            // Randomize the order of the images
            finalImages.sort(() => Math.random() - 0.5);

            // Remove duplicates
            const uniqueImages = Array.from(new Set(finalImages.map(image => image.src)))
                .map(src => finalImages.find(image => image.src === src));

            // uniqueImages.sort(() => Math.random() - 0.5);  // Shuffle if desired
            uniqueImages.sort((a, b) => a.src.split("/").pop().split(".")[0] - b.src.split("/").pop().split(".")[0]); // Sort by image number
            setDisplayedImages(uniqueImages);
            setLoading(false);
        };
        


        fetchImages();
    }, [images]);

    return (
    
        <Grid container>
        {/* if query noLayout === true, don't diplay the header for iframe preview */}

        { !window.location.search.includes("noLayout=true") && 
            <Grid item xs={12}>
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'transparent', elevation: 0, boxShadow: 'none', backgroundImage: 'none', display: { xs: 'none', lg: 'block' } }}>
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'background.default' }}>
                        <Typography
                            variant="h4"
                            fontWeight={800}
                            component="div"
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                            Toutes les photos
                        </Typography>
                        
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            size='large'
                            onClick={() => handleClose()}
                        >
                            <CloseRounded sx={{ fontSize: 40 }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Toolbar sx={{ display: { xs: 'none', lg: 'block' } }} />
            </Grid>}
            <Grid item xs={12}>
                {loading ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "200px",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: { xs: "repeat(auto-fit, minmax(300, 1fr))", sm: "repeat(auto-fit, minmax(400px, 1fr))" },
                            gridAutoRows: { xs: "250px", sm: "330px" },
                            gap: "12px",
                            padding: { xs: "6px", sm: "12px" },
                        }}
                    >
                        {displayedImages.map((image, index) => (
                            <Box
                                key={index}
                                sx={{
                                    gridColumn: "span 1",
                                    gridRow: image.isPortrait ? "span 2" : "span 1", // Portrait spans 2 rows
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    overflow: "hidden",
                                    borderRadius: "8px",
                                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                                    backgroundColor: "#121212",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setZoomedImage(image.src);
                                    setCurrentIndex(index);
                                    setLoadingZoomed(true);
                                }}
                            >
                                <img
                                    src={image.src}
                                    alt={`${image.src}`}
                                    loading="lazy"
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                />
                            </Box>
                        ))}
                    </Box>
                )}



                {/* Modal for Zoom */}
                <Modal
                    open={!!zoomedImage}
                    onClose={() => setZoomedImage(null)}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: "blur(4px)",
                    }}
                    >
                    <Box
                        sx={{
                            position: "relative",
                            maxWidth: "90%",
                            maxHeight: "90%",
                            overflow: "hidden",
                            outline: "none",
                            paddingX: { xs: 0, sm: 8 },
                        }}
                    >
                        {/* Close Button */}
                        {!loadingZoomed && <IconButton
                            onClick={() => setZoomedImage(null)}
                            sx={{
                                position: "absolute",
                                top: "8px", // Position at the top outside the image
                                right: "8px", // Position at the top-right corner
                                zIndex: 1,
                                backgroundColor: "background.default",
                            }}
                            >
                                <CloseRounded />
                        </IconButton>}

                        {/* Previous Button */}
                        {!loadingZoomed &&
                            <IconButton
                                onClick={handlePrevious}
                                sx={{
                                    position: "absolute",
                                    left: "8px", // Move slightly to the left inside the modal container
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    zIndex: 1,
                                    backgroundColor: "background.default",
                                }}
                            >
                                <ArrowBackRounded />
                            </IconButton>
                        }

                        {/* Next Button */}
                        {!loadingZoomed && 
                            <IconButton
                            onClick={handleNext}
                            sx={{
                                position: "absolute",
                                right: "8px", // Move slightly to the right inside the modal container
                                top: "50%",
                                transform: "translateY(-50%)",
                                zIndex: 1,
                                backgroundColor: "background.default",
                            }}
                            >
                                <ArrowForwardRounded />
                            </IconButton>
                        }

                        {/* Image */}
                        {zoomedImage && (
                            <img
                                src={zoomedImage}
                                alt="Zoomed"
                                onLoad={handleImageLoad}
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: "calc(100vh - 128px)", // Account for padding
                                    objectFit: "contain",
                                    borderRadius: "8px",
                                    backgroundColor: "background.default",
                                }}
                            />
                        )}

                        {/* Loader */}
                        {loadingZoomed && (
                        <Box
                            sx={{
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                        )}
                    </Box>
                    </Modal>

            </Grid>
        </Grid>
    );
};

export default AllPhotos;
