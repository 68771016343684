import { CategoryRounded, ChatRounded, ConstructionRounded, Diversity3Rounded, EditRounded, GitHub, Groups3Rounded, HearingRounded, InsightsRounded, LinkedIn, NetworkPingRounded, NewspaperRounded, PhoneRounded, PlaceRounded, PsychologyRounded, PublishedWithChangesRounded, SecurityRounded, SmartphoneRounded, StorefrontRounded, TerminalRounded, TimerRounded, WebRounded, WorkRounded } from "@mui/icons-material";
import { Box, Button, Divider, Fade, Grid, Slide, TextField } from "@mui/material";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../assets/theme";
import TCard from "../components/TCard";
import TDatedTable from "../components/TDatedTable";
import { TAccordionSection, TSubsection } from "../components/TSections";
import TTextWithIcon from "../components/TTextWithIcon";
import { useInView } from "../utils/useInView";

export default function MainPage({projects, photo}) {

    const  navigate  = useNavigate();

    const [value, setValue] = useState(null);
    const [systemScrolling, setSystemScrolling] = useState(false);
    // Opened parcours tab
    const [parcours, setParcours] = useState("formation");

    const [expanded, setExpanded] = useState(false);


    const handleChange = (event, newValue) => {
        setSystemScrolling(false);
        setValue(newValue);

        // go to the section
        let section = document.getElementById(newValue);
        window.scrollTo({
            top: section?.offsetTop - 150,
            behavior: "smooth"
        });
        setSystemScrolling(true);

        // verify scroll is done
        if ((document.documentElement?.scrollTop || document.body?.scrollTop) === section?.offsetTop - 150) {
            setSystemScrolling(false);
        } else {
            setTimeout(() => {
                setSystemScrolling(false);
            }, 1000);
        }
        sessionStorage.setItem("currentSection", newValue);
    }

    // update the value when scrolling
    window.onscroll = () => {
        if (systemScrolling) return;

        let scrollPosition = document.documentElement?.scrollTop || document.body?.scrollTop;
        let sections = ["top", "about", "skills", "parcours", "projects", "photo", "contact"];
        let current = sections[0];

        for (let i = 0; i < sections.length; i++) {
            let section = document.getElementById(sections[i]);
            if (section?.offsetTop - 205 <= scrollPosition) {
                current = sections[i];
                sessionStorage.setItem("currentSection", current);
            }
        }

        setValue(current);
    }

    window.onclose = () => {
        sessionStorage.setItem("currentSection", "top");
    }



    // Data

    const formation = [
        {
            date: "2023 - 2025",
            establishment: "EPSI Lille",
            title: "Titre RNCP niveau 7 - (Bac+5)",
            description: "Chef de projet expert en intelligence artificielle"
        },
        {
            date: "2022 - 2023",
            establishment: "EPSI Lille",
            title: "Titre RNCP niveau 5 - (Bac+3)",
            description: "Concepteur et Développeur d'Applications"
        },
        {
            date: "2020-2022",
            establishment: "Institut Universitaire de Technologie 'A' de Lille",
            title: "Diplôme Universitaire en Technologie - (Bac+2)",
            description: "Informatique"
        },
    ]


    const professionnalExp = [
        {
            date: "2023 - 2025",
            establishment: "Keepapi - EPSI",
            title: "Chef de projet (alternance)"
        },
        {
            date: "2022 - 2023",
            establishment: "Keepapi - EPSI",
            title: "Développeur Fullstack DevOps (alternance)"
        },
        {
            date: "avril - juin 2024",
            establishment: "Keepapi - IUT 'A' de Lille",
            title: "Conception et réalisation d'une solution web d'administrations de données (Stage Développeur Fullstack)"
        },
        {
            date: "passé - juin 2024",
            establishment: "Découverte du monde professionnel",
            title: "Plusieurs expériences professionnelles sans rapport avec l'informatique"
        },
    ]

    const { ref: aboutRef, inView: aboutInView } = useInView();
    const { ref: hardSkillsRef, inView: hardSkillsInView } = useInView();
    const { ref: softSkillsRef, inView: softSkillsInView } = useInView();
    const { ref: languagesRef, inView: languagesInView } = useInView();
    const { ref: parcoursRef, inView: parcoursInView } = useInView();
    const { ref: projectsRef, inView: projectsInView } = useInView();
    const { ref: photoRef, inView: photoInView } = useInView();
    const { ref: contactRef, inView: contactInView } = useInView();

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        let start = 0;
        const end = 960;

        const duration = 1000; // duration in milliseconds
        let incrementTime = (duration / end) * 5;
        const acceleration = 5; // acceleration factor

        const timer = setInterval(() => {
            start += 10;
            setCounter(start);
            if (start >= end) clearInterval(timer);
            incrementTime /= acceleration; // decrease increment time to go faster
        }, incrementTime);

        return () => clearInterval(timer);
    }, [languagesRef]);

    useEffect(() => {
        setValue(null);
        setTimeout(() => {
            setValue(sessionStorage.getItem("currentSection") || "top");
        }, 1300);
    }, []);


    return (
        <>
            <Grid container sx={{paddingLeft: {xs: 2.5, md: 15}, paddingRight: {xs: 2.5, md: 45}, transition: "background-color 0.5s ease-in-out"}}>

                <Box sx={{ position: 'fixed', top: 100, right: 0, zIndex: 1000, backgroundColor: theme.palette.background.default, padding: '10px', margin: '10px', borderRadius: '10px' }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        textColor="background.default"
                        onChange={handleChange}
                        TabIndicatorProps={{sx: {background:'white'}}}
                        ScrollButtonComponent={() => <div></div>}
                        sx={{  
                            display: { xs: 'none', md: 'block' },
                            borderColor: 'divider', 
                            '.MuiTabs-indicator': { left: 0, },
                            '&& .MuiTab-root':{ 
                                textTransform: 'uppercase',
                                alignItems: 'flex-start',
                                paddingY: 0,
                                borderLeft: "2px #555 solid",
                                '&.Mui-selected': { color: theme.palette.primary.main } 
                            },
                        }}
                    >
                        <Slide value={"top"} in={true} direction="right" timeout={900}>
                            <Tab value={"top"} disableRipple label={<Typography fontWeight={600} variant="h6">Début</Typography>} />
                        </Slide>
                        <Slide value={"about"} in={true} direction="right" timeout={950}>
                            <Tab value={"about"} disableRipple label={<Typography fontWeight={600} variant="h6">A propos</Typography>} />
                        </Slide>
                        <Slide value={"skills"} in={true} direction="right" timeout={1000}>
                            <Tab value={"skills"} disableRipple label={<Typography fontWeight={600} variant="h6">Compétences</Typography>} />
                        </Slide>
                        <Slide value={"parcours"} in={true} direction="right" timeout={1040}>
                            <Tab value={"parcours"} disableRipple label={<Typography fontWeight={600} variant="h6">Parcours</Typography>} />
                        </Slide>
                        
                        <Slide value={"projects"} in={true} direction="right" timeout={1080}>
                            <Tab value={"projects"} disableRipple label={<Typography fontWeight={600} variant="h6">Projets informatiques</Typography>} />
                        </Slide>
                        
                        <Slide value={"photo"} in={true} direction="right" timeout={1120}>
                            <Tab value={"photo"} disableRipple label={<Typography fontWeight={600} variant="h6">Photographie</Typography>} />
                        </Slide>
                        <Slide value={"contact"} in={true} direction="right" timeout={1150}>
                            <Tab value={"contact"} disableRipple label={<Typography fontWeight={600} variant="h6">Contact</Typography>} />
                        </Slide>
                    </Tabs>
                </Box>
                

                
                {/* ***************************************** */}
                {/* ******************* TOP ***************** */}
                {/* ***************************************** */}
                <Grid item xs={12} >
                    <section id="top">
                        <Grid item xs={12} >
                        
                            <Slide in={true} direction="right" timeout={1000}>
                                <Fade in={true} timeout={1000}>
                                    <img src="/img/avatar.jpeg" alt="Avatar" style={{ borderRadius: '10%', width: '30vh', height: '30vh', marginTop: '50px' }} />
                                </Fade> 
                            </Slide>
                        </Grid>
                        <Grid item xs={12} overflow={"hidden"} >
                        
                            <Slide in={true} direction="right" timeout={1000}>
                                <Fade in={true} timeout={1000}>
                                    <Typography variant="h1" fontWeight={800} sx={{ marginTop: 4, lineHeight: 1}}>Tanguy<br/> Dessoly</Typography>
                                </Fade>
                            </Slide>
                            <Slide in={true} direction="left" timeout={1000} >
                                <Fade in={true} timeout={1000}>
                                    <Divider sx={{ backgroundColor: theme.typography.color, width: '5%', height: '2px', marginTop: 2, marginBottom: 2, flexGrow: 0 }} />
                                </Fade>
                            </Slide>
                            <Slide in={true} direction="right" timeout={1000}>
                                <Fade in={true} timeout={1000}>
                                    <Typography variant="subtitle1" textTransform={'uppercase'} >Informatique - Photographie</Typography>
                                </Fade>
                            </Slide>

                        </Grid>
                    </section>
                </Grid>






                {/* ***************************************** */}
                {/* ****************** ABOUT **************** */}
                {/* ***************************************** */}
                <Grid item xs={12} sx={{ marginTop: {xs: 15, md: 30 }}}>
                    <section id="about" >
                        <Typography variant="h2">À propos</Typography>
                        
                        <Fade in={aboutInView} timeout={1000}>
                            <Box  sx={{ transform: aboutInView ? "translateY(0)" : "translateY(100px)", transition: "transform 1s" }}>
                                <Typography ref={aboutRef} variant="body1" sx={{ lineHeight: 1 }}>Étudiant alternant en informatique à Lille, France</Typography>
                                <Typography variant="body1" sx={{ marginTop: 6, lineHeight: 1 }}>Lead de projet - Développement - Data - Machine Learning</Typography>
                                <Typography variant="body1" sx={{ marginTop: 6, lineHeight: 1 }}>Photographe amateur</Typography>
                            </Box>
                        </Fade>
                    </section>
                </Grid>












                {/* ***************************************** */}
                {/* ***************** SKILLS **************** */}
                {/* ***************************************** */}
                <Grid item xs={12} sx={{ marginTop: {xs: 15, md: 30 }  }}>
                        
                    <section id="skills" ref={hardSkillsRef}>
                        <Typography variant="h2">Compétences</Typography>

                        
                        <Typography variant="h3" fontWeight={600} sx={{ marginY: 3,lineHeight: 1}}>Hard Skills</Typography>

                        <Grid container>
                            <Fade in={hardSkillsInView} timeout={1000}>
                                <Box sx={{ transform: hardSkillsInView ? "translateY(0)" : "translateY(100px)", transition: "transform 1s", flexGrow: 1 }}>
                                    
                                    <TAccordionSection expanded={expanded} setExpanded={setExpanded} id={"1"} title="Développement logiciel et conception" icon={TerminalRounded}>
                                        <TSubsection
                                            title="Algorithmes et structures de données complexes"
                                        />
                                        <TSubsection
                                            title="Patterns de conception"
                                            details="MVC, Observer, Singleton, Factory, Builder, Strategy, Decorator, Composite, Prototype, Abstract Factory, Null Object"
                                        />
                                        <TSubsection
                                            title="Principes de conception logicielle"
                                            details="DDD, TDD, BDD, Lazy Loading, KISS"
                                        />
                                        <TSubsection
                                            title="Technologies et architectures"
                                            details="Microservices, Serverless"
                                        />
                                    </TAccordionSection>

                                    <TAccordionSection expanded={expanded} setExpanded={setExpanded} id={"2"} title="Développement web et front-end" icon={WebRounded}>
                                        <TSubsection
                                            title="Technologies et frameworks"
                                            details="React, Node, Express, Next, Electron, PWA, SPA"
                                        />
                                        <TSubsection title="Standards et protocoles" details="REST, WebSockets" />
                                        <TSubsection title="Langages" details="JavaScript, TypeScript, HTML,  CSS" />
                                        <TSubsection title="Mise en page" details="Conception visuelle et layout" />
                                        <TSubsection title="SEO" details="Optimisation pour moteurs de recherche" />
                                    </TAccordionSection>

                                    <TAccordionSection expanded={expanded} setExpanded={setExpanded} id={"3"} title="Développement Mobile" icon={SmartphoneRounded}>
                                        <TSubsection
                                            title="Technologies et frameworks"
                                            details="React Native, Expo"
                                        />
                                        <TSubsection title="Langages" details="JavaScript, TypeScript, Kotlin, Java" />
                                    </TAccordionSection>


                                    <TAccordionSection expanded={expanded} setExpanded={setExpanded} id={"4"} title="Infrastructure et DevOps" icon={ConstructionRounded}>
                                        <TSubsection title="Outils" details="Kubernetes, Docker" />
                                        <TSubsection
                                            title="Systèmes d'exploitation"
                                            details="Linux, Windows"
                                        />
                                        <TSubsection
                                            title="Services Cloud"
                                            details="GCP, AWS, Azure, Firebase, OVH"
                                        />
                                        <TSubsection
                                            title="Gestion de bases de données"
                                            details="Cassandra, Redis, MongoDB, MySQL, PostgreSQL, Oracle, SQL Server, SQLite"
                                        />
                                    </TAccordionSection>

                                    <TAccordionSection expanded={expanded} setExpanded={setExpanded} id={"5"} title="Gestion de projets et organisation" icon={CategoryRounded}>
                                        <TSubsection
                                            title="Gestion"
                                            details="Chefferie de projet, gestion de domaine, gestion de projet, gestion de temps, gestion de budget"
                                        />
                                        <TSubsection
                                            title="Méthodologie"
                                            details="Scrum, Kanban, DMAIC, Lean, Agile"
                                        />
                                        <TSubsection
                                            title="Documents"
                                            details="Cahier des charges, spécifications fonctionnelles, spécifications techniques"
                                        />
                                    </TAccordionSection>

                                    <TAccordionSection expanded={expanded} setExpanded={setExpanded} id={"6"} title="Intelligence artificielle, Data Science et Machine Learning" icon={InsightsRounded}>
                                        <TSubsection title="Langage principal" details="Python" />
                                        <TSubsection title="IA générale" details="NLP, Computer Vision, etc." />
                                        <TSubsection
                                            title="Frameworks et bibliothèques ML"
                                            details="TensorFlow, Keras, PyTorch, Scikit-learn, AWS Rekognition"
                                        />
                                        <TSubsection
                                            title="Analyse de données"
                                            details="Pandas, Numpy, Matplotlib, Seaborn, Plotly"
                                        />
                                        <TSubsection title="Environnements" details="Jupyter, Colab, Spyder, PyCharm" />
                                        <TSubsection title="Big Data" details="Spark, Hadoop" />
                                    </TAccordionSection>

                                    <TAccordionSection expanded={expanded} setExpanded={setExpanded} id={"7"} title="Marketing et relations client" icon={StorefrontRounded}>
                                        <TSubsection title="Marketing" details="Envoi de mails, campagnes marketing" />
                                        <TSubsection
                                            title="Relation client"
                                            details="Gestion de la relation client"
                                        />
                                    </TAccordionSection>

                                    <TAccordionSection expanded={expanded} setExpanded={setExpanded} id={"8"} title="Hébergement et services en ligne" icon={NetworkPingRounded}>
                                        <TSubsection title="Hébergement" details="Hébergement web, gestion de serveurs" />
                                        <TSubsection title="Paiement" details="Stripe" />
                                        <TSubsection title="Services" details="Microsoft Graph API / Intégration Outlook" />
                                    </TAccordionSection>

                                    <TAccordionSection expanded={expanded} setExpanded={setExpanded} id={"9"} title="Cybersécurité" icon={SecurityRounded}>
                                        <TSubsection
                                            title="Standards et frameworks"
                                            details="RGPD, ISO 27001, normes de sécurité"
                                        />
                                        <TSubsection
                                            title="Technologies et outils"
                                            details="Single Sign-On (SSO), OAuth, OpenID, JWT, SAML, LDAP, Active Directory, CAS, Shibboleth, WebAuthn"
                                        />
                                        <TSubsection
                                            title="Aspects sécurité"
                                            details="Cryptographie, sécurité des données, sécurité des applications, sécurité des systèmes, sécurité des réseaux, sécurité des utilisateurs"
                                        />
                                    </TAccordionSection>
                                </Box>
                            </Fade>
                        </Grid>
                            {/* Web Development
                            
                            BDD DRIVEN

                            Pattern de conception : MVC, Observer, Singleton, Factory, Builder, Strategy, Decorator, Adapter, Proxy, Facade, Composite, Prototype, Abstract Factory, Builder, Null Object
                            
                            Serverless, 
                            GraphQL, 
                            PWA
                            SEO
                            SPA

                            Composition
                            
                            Java
                            Kotlin
                            C

                            selenium
                            locust

                            mise en page
                            graphisme
                            design
                            retouche photo
                            responsive design
                            
                            spé cybersécu

                            isitnahim

                            ci/cd
                            github actions
                            gitlab ci/cd

                            jack of all trade master of some

                            recherche de compétences, liaisons projets
                            ouvre le bon accordeon
                            tout dévelloper
                            
                            */}

                        <Typography variant="h3" fontWeight={600} sx={{  marginY: 3}} ref={softSkillsRef}>Soft Skills</Typography>
                        <Fade in={softSkillsInView} timeout={1000}>
                            <Box sx={{ transform: softSkillsInView ? "translateY(0)" : "translateY(100px)", transition: "transform 1s" }}>
                                <Typography variant="body1" fontWeight={600} textTransform={'uppercase'} >
                                    <TTextWithIcon text="ADAPTABILITÉ" icon={PublishedWithChangesRounded} />
                                    <TTextWithIcon text="COMMUNICATION" icon={ChatRounded} />
                                    <TTextWithIcon text="ÉCRITURE" icon={EditRounded} />
                                    <TTextWithIcon text="ÉCOUTE" icon={HearingRounded} />
                                    <TTextWithIcon text="ÉTHIQUE DU TRAVAIL" icon={WorkRounded} />
                                    <TTextWithIcon text="MANAGEMENT D'ÉQUIPE" icon={Groups3Rounded} />
                                    <TTextWithIcon text="MANAGEMENT DU TEMPS" icon={TimerRounded} />
                                    <TTextWithIcon text="MÉDIATION" icon={PsychologyRounded} />
                                    <TTextWithIcon text="RECHERCHE D'INFORMATIONS" icon={NewspaperRounded} />
                                    <TTextWithIcon text="TRAVAIL D'ÉQUIPE" icon={Diversity3Rounded} />
                                </Typography>
                            </Box>
                        </Fade>
                            
                        
                        <Typography variant="h3" fontWeight={600}  sx={{  marginBottom: 3, marginTop: 8}} ref={languagesRef}>Langues parlées</Typography>
                        <Fade in={languagesInView} timeout={1000}>
                            <Box sx={{ transform: languagesInView ? "translateY(0)" : "translateY(100px)", transition: "transform 1s" }}>
                                <Typography variant="body1" textTransform={'uppercase'} fontWeight={600} sx={{ lineHeight: 1,marginTop: 6, color: 'hsla(0, 0%, 100%, .6)'}}>Français</Typography>
                                <Typography variant="body1"  fontWeight={500} sx={{lineHeight: 1, marginTop: 0}}>Langue maternelle</Typography>

                                <Typography variant="body1" textTransform={'uppercase'} fontWeight={600} sx={{ lineHeight: 1, marginTop: 6, color: 'hsla(0, 0%, 100%, .6)'}}>Anglais</Typography>
                                <Typography variant="body1"fontWeight={500} sx={{lineHeight: 1, marginTop: 0}}>Niveau {counter > 945 ? "C1" : counter > 785 ? "B2" : counter > 550 ? "B1" : counter > 225 ? "A2" : "A1"} - score TOEIC : {counter}/990</Typography>

                                <Typography variant="body1" textTransform={'uppercase'} fontWeight={600} sx={{ lineHeight: 1, marginTop: 6, color: 'hsla(0, 0%, 100%, .6)'}}>Allemand</Typography>
                                <Typography variant="body1" fontWeight={500} sx={{lineHeight: 1, marginTop: 0}}>Rudimentaire</Typography>
                            </Box>  
                        </Fade>
                    </section>
                </Grid>















                {/* ***************************************** */}
                {/* *************** PARCOURS **************** */}
                {/* ***************************************** */}
                <Grid item xs={12} sx={{ marginTop: {xs: 15, md: 30 }  }}>
                    <section id="parcours" ref={parcoursRef}>
                        <Typography variant="h2">Parcours</Typography>
                        <Fade in={parcoursInView} timeout={1000}>
                            <Box  sx={{ transform: parcoursInView ? "translateY(0)" : "translateY(100px)", transition: "transform 1s" }}>
                                <Button onClick={() => setParcours("formation")} variant={parcours === "formation" ? "contained" : "outlined"} color="text" sx={{ mr:1, color: parcours === "formation" ? "secondary.main" : "text" , marginTop: 2 }} > Formation </Button> 
                                <Button onClick={() => setParcours("professionnel")} variant={parcours === "professionnel" ? "contained" : "outlined"} color="text" sx={{color: parcours === "professionnel" ? "secondary.main" : "text", marginTop: 2 }} > Professionnel </Button> 
                                
                                {parcours === "formation" && (
                                    <Grid container>
                                        <TDatedTable datas={formation} />
                                    </Grid>
                                )}

                                {parcours === "professionnel" && (
                                    <Grid container>
                                        <TDatedTable datas={professionnalExp} />
                                    </Grid>
                                )}
                            </Box>
                        </Fade>
                    </section>
                </Grid>








                {/* ***************************************** */}
                {/* **************** PROJETS **************** */}
                {/* ***************************************** */}
                <Grid item xs={12} sx={{ marginTop: {xs: 15, md: 30 }  }}>
                    <section id="projects" ref={projectsRef}>
                        <Typography variant="h2">Projets informatiques</Typography>

                        <Fade in={projectsInView} timeout={1000}>
                            <Box  sx={{ transform: projectsInView ? "translateY(0)" : "translateY(100px)", transition: "transform 1s" }}>
                                <Grid container spacing={2}>
                                    
                                    {projects.map((p, i) => (
                                        <Grid key={p.url} item xs={12} md={4} >
                                            <TCard title={p.title} image={p.previewImage} description={p.projectName} tech={p.tech} onClick={() => {navigate(p.url)}} alt={p.alt} />
                                        </Grid>        
                                    ))}
                                </Grid>
                            </Box>
                        </Fade>
                    </section>
                </Grid>












                {/* ***************************************** */}
                {/* ***************** PHOTO ***************** */}
                {/* ***************************************** */}
                <Grid item xs={12} sx={{ marginTop: {xs: 15, md: 30 }  }}>
                    <section id="photo" ref={photoRef}>
                        <Typography variant="h2">Photographie</Typography>

                        <Fade in={photoInView} timeout={1000}>
                            <Box  sx={{ transform: photoInView ? "translateY(0)" : "translateY(100px)", transition: "transform 1s" }}>
                        
                                <Grid container spacing={2}>
                                    <Grid key={"all"} item xs={12} md={4} >
                                        <TCard 
                                            title={"Toutes les photos"} 
                                            onClick={() => { navigate("/photos") }} 
                                            alt={"Toutes les photos"}
                                            image={"/img/all.webp"}
                                        />
                                    </Grid>   
                                    {photo.map((p, i) => (
                                        <Grid key={p.url} item xs={12} md={4} >
                                            <TCard title={p.title} image={p.previewImage} onClick={() => {navigate(p.url)}} alt={p.alt} />
                                        </Grid>        
                                    ))}
                                </Grid>
                            </Box>
                        </Fade>
                    </section>
                </Grid>












                {/* ***************************************** */}
                {/* **************** CONTACT **************** */}
                {/* ***************************************** */}
                <Grid item xs={12} sx={{ marginTop: {xs: 15, md: 30 }  }}>
                    <section id="contact" ref={contactRef}>
                        <Typography variant="h2" marginBottom={10}>Contact</Typography>

                        <Fade in={contactInView} timeout={1000}>
                            <Box sx={{ transform: contactInView ? "translateY(0)" : "translateY(100px)", transition: "transform 1s" }}>
                                <Grid container>
                                    <Grid item xs={12} md={4} >
                                        <Typography variant="caption" fontWeight={800} fontSize={16} color={"grey"} sx={{ lineHeight: 1}}>Rester en contact</Typography>
                                        <Grid container sx={{marginTop: 2, alignItems: 'center'}} >
                                            <PhoneRounded fontSize={"large"} sx={{marginRight: 1}}/><Typography variant="body1" fontWeight={500} sx={{ lineHeight: 1, '&:hover': {  cursor: 'pointer', textDecoration: 'underline' } }} onClick={() => window.open("tel:+33695237569")}>+33 6 95 23 75 69</Typography> 
                                        </Grid>
                                        <Grid container sx={{marginTop: 1, alignItems: 'center'}}>
                                            <PlaceRounded fontSize={"large"} sx={{marginRight: 1}}/><Typography variant="body1" fontWeight={500} sx={{ lineHeight: 1, '&:hover': {  cursor: 'pointer', textDecoration: 'underline' } }} onClick={() => window.open("https://www.google.com/maps/@50.6421647,3.1110974,12z")}>Lille, France</Typography>
                                        </Grid> 
                                    </Grid>
                                    <Grid item xs={12} md={4} >
                                        <Typography variant="caption" fontWeight={800} fontSize={16} color={"grey"} >Réseaux</Typography>
                                        <Grid container sx={{marginTop: 2, alignItems: 'center'}}>
                                            <LinkedIn fontSize={"large"} sx={{marginRight: 1}}/><Typography variant="body1" fontWeight={500} sx={{ lineHeight: 1, '&:hover': {  cursor: 'pointer', textDecoration: 'underline' } }} onClick={() => window.open("https://fr.linkedin.com/in/tanguydessoly")} >LinkedIn</Typography>
                                        </Grid>
                                        <Grid container sx={{marginTop: 1, alignItems: 'center'}}>
                                            <GitHub fontSize={"large"} sx={{marginRight: 1}}/><Typography variant="body1" fontWeight={500} sx={{ lineHeight: 1, '&:hover': {  cursor: 'pointer', textDecoration: 'underline' } }} onClick={() => window.open("https://github.com/TanguyDessoly")}>GitHub</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Typography variant="h4"  fontWeight={700}  sx={{ lineHeight: 1, marginTop: 10, textTransform:"uppercase"}}>Laisser un message</Typography>
                                <form action="https://formspree.io/f/xleayorj" method="POST">
                                    <Grid container spacing={2} > 
                                        <Grid item xs={12} md={6} >
                                            <TextField name="name" fullWidth label="Nom" variant="standard" margin="normal" required placeholder="John Doe" />
                                        </Grid>
                                        <Grid item xs={12} md={6} >
                                            <TextField name="email" fullWidth label="Email" variant="standard" margin="normal"  required placeholder="johndoe@domain.com" />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <TextField name="message" fullWidth label="Message" variant="standard" margin="normal"  multiline rows={4} required placeholder="Hey, ton site est vraiment incroyable !" />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Button type="submit" variant="contained" color="text" sx={{ mr:1, color:"background.default" , marginTop: 2 }} > Envoyer </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Fade>
                    </section>
                </Grid>

            </Grid>
            

            {/****************************************** */}
            {/**************** FOOTER ****************** */}
            {/****************************************** */}
            <Grid item xs={12} sx={{ marginTop: 6, marginBottom: 2, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <Typography variant="caption"  sx={{ lineHeight: 1}}>© 2024 Tanguy Dessoly - Tous droits réservés</Typography>
            </Grid>
        </>
    );
}