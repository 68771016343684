import { CloseRounded, MenuRounded } from '@mui/icons-material';
import { Button, Drawer, IconButton, Slide, Tab, Tabs } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link, Outlet } from 'react-router-dom';
import theme from '../assets/theme';

export default function LayoutAppBar() {

    const [showDrawer, setShowDrawer] = React.useState(false);
    
    
    const [value, setValue] = React.useState(null);
    const [systemScrolling, setSystemScrolling] = React.useState(false);

    const handleChange = (event, newValue) => {
        setSystemScrolling(false);
        setValue(newValue);

        // go to the section
        let section = document.getElementById(newValue);
        window.scrollTo({
            top: section?.offsetTop - 150,
            behavior: "smooth"
        });
        setSystemScrolling(true);

        // verify scroll is done
        if ((document.documentElement?.scrollTop || document.body?.scrollTop) === section?.offsetTop - 150) {
            setSystemScrolling(false);
            setShowDrawer(false);
        } else {
            setTimeout(() => {
                setSystemScrolling(false);
                setShowDrawer(false);
            }, 1000);
        }
    }

    // update the value when scrolling
    window.onscroll = () => {
        console.log(systemScrolling);
        if (systemScrolling) return;

        let scrollPosition = document.documentElement?.scrollTop || document.body?.scrollTop;
        let sections = ["top", "about", "skills", "parcours", "projects", "photo", "contact"];
        let current = sections[0];

        for (let i = 0; i < sections.length; i++) {
            let section = document.getElementById(sections[i]);
            if (section?.offsetTop - 205 <= scrollPosition) {
                current = sections[i];
                sessionStorage.setItem("currentSection", current);
            }
        }

        setValue(current);
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'background.default', elevation: 0, boxShadow: 'none', backgroundImage: 'none' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        size='large'
                        sx={{ display: { xs: 'block', sm: 'none' } }}
                        onClick={() => setShowDrawer(!showDrawer)}
                    >
                        { showDrawer ? <CloseRounded sx={{ fontSize: 40 }} /> : <MenuRounded sx={{ fontSize: 40 }} />  }
                    </IconButton>
                    
                    

                    <Link to="/" style={{ textDecoration: 'none', color: 'white', display: { xs: 'none', sm: 'block' } }}>
                        <Slide in={true} direction="down" timeout={1080}>
                            <Typography
                                variant="h4"
                                fontWeight={800}
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' }, paddingLeft: 12, marginTop: 4, paddingBottom: 2 }}
                            >
                                Tanguy Dessoly
                            </Typography>
                        </Slide>
                    </Link>
                    
                    <Slide in={true} direction="down" timeout={1080}> 
                        <Button
                            variant="outlined"
                            sx={{   
                                marginTop: 4,
                                borderColor: theme.typography.color,
                                padding: '0.5rem 1rem',
                                display: { xs: 'none', sm: 'block' }
                            }}
                            onClick={() => {
                                let section = document.getElementById("contact");
                                window.scrollTo({
                                    top: section?.offsetTop - 85,
                                    behavior: "smooth"
                                });
                            }}
                        >
                            <Typography
                                variant="h6"
                                fontWeight={600}
                                component="div"
                                color={theme.typography.color}
                                sx={{ display: { xs: 'none', sm: 'block' }, padding: 1, paddingX: 3.7 }}
                            >
                                → ME CONTACTER ←
                            </Typography>
                        </Button>
                    </Slide>
                </Toolbar>
            </AppBar>


            {/* <Box sx={{ position: 'fixed', top: 100, right: 0, zIndex: 1000, backgroundColor: theme.palette.background.default, padding: '10px', margin: '10px', borderRadius: '10px', display: { xs: 'none', md: 'block' }, }}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    textColor="background.default"
                    onChange={handleChange}
                    TabIndicatorProps={{sx: {background:'white'}}}
                    sx={{  
                        borderColor: 'divider', 
                        '.MuiTabs-indicator': { left: 0, },
                        '&& .MuiTab-root':{ 
                            textTransform: 'uppercase',
                            alignItems: 'flex-start',
                            paddingY: 0,
                            borderLeft: "2px #555 solid",
                            '&.Mui-selected': { color: theme.palette.primary.main } 
                        },
                    }}
                >
5                   <Tab value={"top"} disableRipple label={<Typography fontWeight={600} variant="h6">Début</Typography>} />
                    <Tab value={"about"} disableRipple label={<Typography fontWeight={600} variant="h6">A propos</Typography>} />
                    <Tab value={"skills"} disableRipple label={<Typography fontWeight={600} variant="h6">Compétences</Typography>} />
                    <Tab value={"parcours"} disableRipple label={<Typography fontWeight={600} variant="h6">Parcours</Typography>} />
                    <Tab value={"projects"} disableRipple label={<Typography fontWeight={600} variant="h6">Projets informatiques</Typography>} />
                    <Tab value={"photo"} disableRipple label={<Typography fontWeight={600} variant="h6">Photographie</Typography>} />
                    <Tab value={"contact"} disableRipple label={<Typography fontWeight={600} variant="h6">Contact</Typography>} />
                </Tabs>
            </Box> */}
            
            <Drawer
                anchor="right"
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                hideBackdrop={true}
                PaperProps={ { sx: { backgroundColor: 'background.default', backgroundImage: 'none', width: '100vw' } } }
                transitionDuration={{ enter: 500, exit: 500 }}

            >
                <Toolbar />
                <Toolbar />
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    textColor="background.default"
                    onChange={handleChange}
                    TabIndicatorProps={{sx: {background:'white'}}}
                    sx={{  
                        borderColor: 'divider', 
                        paddingLeft: 2,
                        '.MuiTabs-indicator': { left: 0, },
                        '&& .MuiTab-root':{ 
                            textTransform: 'uppercase',
                            alignItems: 'flex-start',
                            paddingY: 0,
                            borderLeft: "2px #555 solid",
                            '&.Mui-selected': { color: theme.palette.primary.main } 
                        },
                    }}
                >
5                   <Tab value={"top"} disableRipple label={<Typography fontWeight={600} variant="h6">Début</Typography>} />
                    <Tab value={"about"} disableRipple label={<Typography fontWeight={600} variant="h6">A propos</Typography>} />
                    <Tab value={"skills"} disableRipple label={<Typography fontWeight={600} variant="h6">Compétences</Typography>} />
                    <Tab value={"parcours"} disableRipple label={<Typography fontWeight={600} variant="h6">Parcours</Typography>} />
                    <Tab value={"projects"} disableRipple label={<Typography fontWeight={600} variant="h6">Projets informatiques</Typography>} />
                    <Tab value={"photo"} disableRipple label={<Typography fontWeight={600} variant="h6">Photographie</Typography>} />
                    <Tab value={"contact"} disableRipple label={<Typography fontWeight={600} variant="h6">Contact</Typography>} />
                </Tabs>
            </Drawer>
                <Toolbar />
            
            <Outlet />

        </Box>
    );
}
