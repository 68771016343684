import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React, { useEffect } from "react";
import theme from "../assets/theme";

const TCard = React.memo(({title, description, image, tech, onClick, alt}) => {

    const [displayImage, setDisplayImage] = React.useState(image);
    const [fade, setFade] = React.useState(false);

    useEffect(() => {
        if (image !== "dark-js-preview") return;
        setDisplayImage("https://images.vscodethemes.com/TanguyDessoly.plus-dark-plus/dark-js-preview--Ck.svg");
        let currentText = "https://images.vscodethemes.com/TanguyDessoly.plus-dark-plus/dark-js-preview--Ck.svg"
        let langages = ["go", "js", "java", "cpp", "php", "py", "html", "css"]
        let currentIndex = 1;

        const interval = setInterval(() => {
            currentIndex = (currentIndex + 1) % langages.length;
            const currentLangage = langages[currentIndex];
            const newImage = currentText.replace(/dark-\w+-preview/, `dark-${currentLangage}-preview`);
            setFade(true);
            setTimeout(() => {
                setDisplayImage(newImage);
                setFade(false);
            }, 500); // Duration of the fade out
        }, 5000);

        return () => clearInterval(interval);
    }, [image]);

    return (
        <Card 
            elevation={0} 
            sx={{ 
                maxWidth: 430,
                borderRadius:0,
                transition: "transform 0.15s ease-in-out",
                cursor: 'pointer',
                ':hover': { 
                    transform: 'translateY(-.5rem)',
                    transition: "transform .15s ease-in-out, -webkit-transform .15s ease-in-out"}
            }}
            onClick={() => {onClick && onClick()}}
        > 
            {(typeof image === 'string') ? <CardMedia
                component="img"
                alt={alt}
                image={displayImage}
                sx={{ height: 300, objectFit: "contain", opacity: fade ? 0.5 : 1, transition: "opacity 1s ease-in-out" }}
            /> : displayImage}

            <CardContent sx={{ backgroundColor: theme.palette.background.default, px: 0 }}>
                <Typography gutterBottom variant="h5" component="div" fontWeight={600} m={0}>
                    {title}
                </Typography>

                <Typography variant="body2" color="text.secondary" sx={{ pl: 0}}>
                    {description}
                </Typography>
                
                <Typography variant="body2" textTransform={"uppercase"} fontWeight={600} sx={{ pt: 1}}>
                    {tech}
                </Typography>
            </CardContent>
        </Card>
    );
})

export default TCard;