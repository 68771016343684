import { Box, Typography } from "@mui/material";

const TTextWithIcon = ({ text, icon: Icon,  }) => (
    <Box>
        <Box display="flex" alignItems="center" sx={{ marginY: 3 }}>
            <Icon sx={{ fontSize: 30, marginRight: 1 }} color="primary" />
            <Typography
                variant="h4"
                fontWeight={600}
                textTransform="uppercase"
            >
                {text}
            </Typography>
        </Box>
    </Box>
);

export default TTextWithIcon;