import { Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function TDatedTable({ datas }) {
    const [visibleItems, setVisibleItems] = useState([]);

    useEffect(() => {
        // Make items appear with a delay
        const timeoutIds = [];

        datas.forEach((item, index) => {
            const timeoutId = setTimeout(() => {
                setVisibleItems((prev) => [...prev, item]);
            }, (index * 0.8) * 100); 

            timeoutIds.push(timeoutId);
        });

        return () => {
            timeoutIds.forEach((id) => clearTimeout(id));
        };
    }, [datas]);

    return (
        <Grid container direction="column">
            {datas.map((item, index) => {
                const style = {
                    transition: `transform 0.5s ${index * 0.1}s`,
                    transform: visibleItems.includes(item) ? 'translateX(0)' : 'translateX(-150%)',
                    willChange: 'transform', 
                };

                return (
                    <div key={index} style={style}>
                        <Grid container>
                            <Grid item xs={4} md={3}>
                                <Typography variant="h6" fontWeight={600} sx={{ lineHeight: 1.2, marginTop: 2 }}>
                                    {item.date}
                                </Typography>
                                <Typography variant="caption" fontWeight={400} sx={{ lineHeight: 1, marginTop: 2 }}>
                                    {item.establishment}
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={9}>
                                <Typography variant="h5" fontWeight={600} sx={{ lineHeight: 1.2, marginTop: 2 }}>
                                    {item.title}<br />
                                    {item.description}
                                </Typography>
                            </Grid>
                            {index !== datas.length - 1 && (
                                <Divider
                                    sx={{ backgroundColor: "grey", width: '100%', height: '2px', marginTop: 2, marginBottom: 2 }}
                                />
                            )}
                        </Grid>
                    </div>
                );
            })}
        </Grid>
    );
}
