import CssBaseline from '@mui/material/CssBaseline';
import theme from './assets/theme';
import { responsiveFontSizes, ThemeProvider } from '@mui/material';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import PrimarySearchAppBar from './layout/AppBar';
import ErrorPage from './views/404';
import MainPage from './views/MainPage';
import ProjectPage from './views/ProjectPage';
import PhotoPage from './views/PhotoPage';
import AllPhotos from './views/AllPhotos';

function App() {

    const responsiveFontSizeTheme =  responsiveFontSizes(theme);


    
    const projects = [
        {
            url: "/project/portfolio",
            title: "Portfolio",
            projectName: "Portfolio",
            description: "Site web personnel pour présenter mes projets et mes photos. Vous naviguez actuellement dessus.",
            previewImage: "/img/portfolio.png",
            tech: "React.js - Material-UI - React-Router",
            status: "En cours",
            from: "Projet personnel",
            content: (
                <>
                    Site web personnel pour présenter mes projets et mes photos.<br/><br/>
                    En cours de développement.
                </>
            )
        },
        {
            url: "/project/companion-app",
            title: "Compagnon de jeu",
            description: "Application compagnon pour Counter Strike 2, avec des fonctionnalités d'IA pour aider les joueurs à prendre des décisions et améliorer la qualité de vie générale de la partie.",
            projectName: "Companion App",
            previewImage: "/img/MoreToCome.png",
            tech: "TypeScript - Nextron - TensorFlow",
            status: "En cours",
            from: "Projet personnel",
            images: [],
            content: (
                <>
                    Application compagnon pour un jeu vidéo, avec des fonctionnalités d'IA pour aider les joueurs à prendre des décisions et améliorer la qualité de vie générale de la partie.<br/><br/>
                    En cours de développement.
                </>
            )
        },
        {
            url: "/project/amazing",
            title: "Gestion et réalisation d'algorithme de clustering et de recherche de motifs fréquents",
            projectName: "Amazing",
            description: "Recommandation de produits à partir de données de vente.",
            previewImage: "/img/graph.png",
            tech: "Python - Pandas - Scikit-learn - Apriori - K-means - DBSCAN - Matplotlib - Seaborn",
            alt: "Gestion de projet",
            images: ["/img/graph.png", "/img/data-prep.png", "/img/clusters.png"],
            from: "Projet en école supérieure",
            status: "Terminé",
            content: (
                <>
                    Recommandation de produits à partir de données de vente.<br/><br/>
                    Écriture du cahier des charges, d'une note de cadrage ainsi que d'un rapport de soutenance.<br/><br/>
                    Utilisation d'algorithmes de clustering d'utilisateurs et de recherche de motifs fréquents pour recommander des produits à des clients par groupes.<br/><br/>
                    Réalisation de différents graphiques pour visualiser les données et les résultats.
                </>
            )
        },
        {
            url: "/project/crm",
            title: "CRM",
            projectName: "Client Relationship Management",
            description: "Outil de gestion de la relation client.",
            previewImage: "/img/crm.png",
            tech: "React.js - Material-UI - Node.js - Express - MySQL",
            alt: "Gestion de projet",
            images: [],
            from: "Entreprise",
            status: "En développement",
            content: (
                <>
                    Outil de gestion de la relation client.<br/><br/>
                    Réalisation d'un outil de gestion de la relation client pour une entreprise.<br/><br/>
                    Ouverture en SaaS prévue.<br/><br/>
                </>
            )
        },
        {
            url: "/project/gestion-de-projet",
            title: "Divers projets impliquant la gestion de projet",
            projectName: "Gestion de projet",
            description: "Réalisation de différents documents de gestion de projet.",
            previewImage: "/img/gestion-de-projet.png",
            tech: "Agile - Scrum - Gantt - UML",
            alt: "Gestion de projet",
            images: [],
            from: "Projet en école supérieure",
            status: "Terminé",
            content: (
                <>
                    Réalisation de différents documents de gestion de projet à l'aide de méthodes agiles, de diagrammes UML, de diagrammes de Gantt, etc.
                    Réalisation de cahiers des charges, de spécifications fonctionnelles, de notes de cadrage, de rapports de soutenance, etc.
                </>
            )
        },
        {
            url: "/project/outil-ticketing",
            title: "Outil de suivi de projet et de gestion de tâches",
            projectName: "Suivi de projet",
            description: "Outil de suivi de projet et de gestion de tâches.",
            previewImage: "/img/MoreToCome.png",
            tech: "React.js - Material-UI - Node.js - Express - MySQL",
            alt: "Gestion de projet",
            images: [],
            from: "Entreprise",
            status: "Fonctionnel",
            content: (
                <>
                    Outil de suivi de projet et de gestion de tâches.<br/><br/>
                    Réalisation d'un outil de suivi de projet et de gestion de tâches pour une entreprise.<br/><br/>
                    Utilisation de React.js pour le front-end, et de Node.js avec Express pour le back-end.<br/><br/>
                    Base de données MySQL pour stocker les données.<br/><br/>
                    Tableau Kanban pour visualiser les tâches à réaliser ainsi que diagramme de Gantt pour visualiser l'avancement du projet.
                    Gestion des tickets et des utilisateurs.<br/><br/>
                    Outil internes pour les employés de l'entreprise.
                </>
            )
        },
        {
            url: "/project/suivi-evenements",
            title: "Plateforme de gestion d'événements",
            projectName: "Gestion d'événements",
            description: "Plateforme de gestion d'événements.",
            previewImage: "/img/events.png",
            tech: "React.js - Material-UI - Node.js - Express - MySQL - SAML - Shibboleth",
            alt: "Plateforme de gestion d'événements",
            images: [],
            from: "Entreprise",
            status: "En ligne",
            content: (
                <>
                    Plateforme de gestion d'événements pour une université.<br/><br/>
                    Suivi de la création, de la modification et de la suppression d'événements.<br/><br/>
                    Gestion des utilisateurs et des droits.<br/><br/>
                    Gestion des salles et des réservations.<br/><br/>
                    Liaison Hyperplanning et SSO interne en SAML avec Shibboleth.<br/><br/>
                </>
            )
        },
        {
            url: "/project/client-mail",
            title: "Application de gestion de mails",
            projectName: "NobleMail",
            description: "Application de gestion de mails.",
            previewImage: "/img/MoreToCome.png",
            tech: "React.js - Material-UI - Node.js - Express - Electron - NodeMailer",
            alt: "Plateforme de gestion de mails",
            images: [],
            from: "Projet en école supérieure",
            status: "Terminé",
            content: (
                <>
                    Application de gestion de mails.<br/><br/>
                    Réalisation d'une application de gestion de mails avec Electron.<br/><br/>
                    Utilisation de React.js pour le front-end, et de Node.js avec Express pour le back-end et NodeMailer pour l'envoi de mails.<br/><br/>
                    Application permettant de gérer ses mails, de les classer, de les archiver, de les supprimer, etc.<br/><br/>
                </>
            )
        },
        {
            url: "/project/arosaje",
            title: "Application mobile de reconnaissance de plantes",
            projectName: "Arosaje",
            description: "Application mobile de gestion de plantes en communauté avec reconnaissance de plantes par photo.",
            previewImage: "/img/arosaje-fake.jpg",
            tech: "Python - TensorFlow - AWS - React Native",
            alt: "Reconnaissance de différentes plantes",
            images: ["/img/arosaje.png", "/img/arosaje-2.png"],
            from: "Projet en école supérieure",
            status: "Terminé",
            content: (
                <>
                    Application mobile de reconnaissance de plantes.<br/><br/>
                    En utilisant un modèle de deep learning, l'application est capable de reconnaître une plante à partir d'une photo.<br/><br/>
                    Le modèle est hébergé sur AWS pour une utilisation à distance.<br/><br/>
                    L'application permet également de gérer ses plantes en communauté, avec des fonctionnalités de partage et de conseils.
                </>
            )
        },
        {
            url: "/project/facial-recognition",
            title: "Application mobile de reconnaissance faciale",
            projectName: "IsItNahim?",
            description: "Application mobile qui permet de reconnaître un visage spécifique.",
            previewImage: "/img/MoreToCome.png",
            tech: "Python - TensorFlow - AWS - React Native",
            alt: "Reconnaissance d'un visage spécifique",
            images: [],
            from: "Projet en école supérieure",
            status: "Terminé",
            content: (
                <>
                    Application mobile de reconnaissance faciale.<br/><br/>
                    En utilisant un modèle de deep learning, l'application est capable de reconnaître un visage spécifique.<br/><br/>
                    Le modèle est hébergé sur AWS pour une utilisation à distance.
                </>
            )
        },
        {
            url: "/project/theme-vscode",
            title: "Theme VSCode",
            projectName: "+Dark+",
            description: "Thème pour Visual Studio Code, avec des couleurs contrasées et des nuances de gris pour une meilleure lisibilité.",
            previewImage: "dark-js-preview",
            tech: "Java — JavaFX",
            alt: "Prévisualisation dans un éditeur de code du thème",
            images: ["https://images.vscodethemes.com/TanguyDessoly.plus-dark-plus/dark-js-preview--Ck.svg"],
            from: "Projet personnel",
            status: "Terminé",
            content: (
                <>
                    Thème pour Visual Studio Code, avec des couleurs contrasées et des nuances de gris pour une meilleure lisibilité.<br/><br/>
                    Je l'utilise personnellement pour coder, et je le mets à disposition pour ceux qui le souhaitent.<br/><br/>
                    <a href="https://marketplace.visualstudio.com/items?itemName=TanguyDessoly.plus-dark-plus">Disponible sur le marketplace</a>

                </>
            )
        },
        {
            url: "/project/supervision",
            title: "Supervision de developpement interne",
            projectName: "Supervision",
            description: "Outil de supervision de développement interne.",
            previewImage: "/img/supervision.png",
            tech: "React.js - Material-UI - Node.js - Express - MySQL",
            alt: "Supervision de développement",
            images: [],
            from: "Entreprise",
            status: "En ligne",
            content: (
                <>
                    Outil de supervision de développement interne.<br/><br/>
                    Réalisation d'un outil de supervision de développement interne pour une entreprise.<br/><br/>
                    Utilisation de React.js pour le front-end, et de Node.js avec Express pour le back-end.<br/><br/>
                    Base de données MySQL pour stocker les données.<br/><br/>
                    Outil internes pour les employés de l'entreprise.<br/><br/>
                    Permet de définir les entités et les relations entre elles, de définir des règles de validation, de définir des règles de visibilité, etc.<br/><br/>
                    Le déploiement ainsi que le suivi de ceux-ci sont également gérés par l'outil.<br/><br/>
                    Réalise un CRUD complet et avancé sur les entités définies.<br/><br/>
                    Assure la qualité du code et la sécurité des données.<br/><br/>
                    Les entités sont capable de générer des sites entiers, la gestion des utilisateurs, des droits, des logs, etc.<br/><br/>
                    Outil en constante évolution.
                </>
            )
        },
        {
            url: "/project/ply-viewer",
            title: "Visualisateur de modèles 3D",
            projectName: "PLY Viewer",
            description: "Visualisateur de modèles 3D au format PLY, avec des fonctionnalités de zoom, rotation et translation. Le modèle est chargé depuis un fichier PLY, et les transformations sont effectuées en temps réel. Ajout d'un éclairage et gestion de la couleur pour une meilleure immersion.",
            previewImage: "/img/3DDemo.png",
            tech: "Java — JavaFX — FXML — SceneBuilder",
            alt: "Image d'un modèle 3D",
            images: ["/img/3DDemo.png", "/img/Apple.png", "/img/Shoe.png"],
            from: "Projet universitaire",
            status: "Terminé",
            content: (
                <>
                    Visualisateur de modèles 3D avec des fonctionnalités de zoom, rotation et translation.<br/><br/>
                    Le modèle est chargé depuis un fichier PLY, et les transformations sont effectuées en temps réel.<br/><br/>
                    Éclairage et gestion de la couleur en temps réel pour une meilleure immersion.
                </>
            )
        },
        {
            url: "/project/web-server",
            title: "Serveur Web HTTPS en partant de rien",
            projectName: "Web Serveur",
            description: "Création d'un serveur web HTTPS en partant de zéro, avec des fonctionnalités de routage, gestion des signaux, gestion des erreurs et support des différents MIME types.",
            previewImage: "/img/MoreToCome.png",
            tech: "C",
            status: "Terminé",
            from: "Projet universitaire",
            content: (
                <>
                    Création d'un serveur web HTTPS en partant de zéro, avec des fonctionnalités de routage, gestion des signaux, gestion des erreurs et support des différents MIME types.
                </>
            )
        },
        {
            url: "/project/fsog",
            title: "Utilitaire de nuances de gris pour l'impression en noir et blanc",
            projectName: "Fifty Shades of Grey",
            description: "Outil d'aide au choix de couleurs en fonction de leur niveau de gris en vue d'une impression.",
            previewImage: "/img/FSoG-2.png",
            tech: "Java — JavaFX",
            alt: "Image d'un dégradé de gris",
            images: ["/img/FSoG.png", "/img/FSoG-2.png"],
            from: "Projet universitaire",
            status: "Terminé",
            content: (
                <>
                    Ne vous êtes vous jamais retrouvé avec un diagramme incompréhensible à cause des gris de même nuance ?<br/><br/>
                    Cet outil a pour but de vous aider, avec une fonction d'export et de contrôles intuitifs !
                </>
            )
        },
    ]

    
    const photo = [
        {
            url: "/photo/marie",
            title: "Marie",
            previewImage: "/img/Marie/Output/0.webp",
            images: [...Array.from({length: 20}, (_, i) => `/img/Marie/Output/${i+1}.webp`)],
            imageCaption: {
                // 0: "Portrait de Marie",
            },
            alt: "Portrait de Marie"
        },
        {
            url: "/photo/houches",
            title: "Houches",
            previewImage: "/img/Houches/Output/0.webp",
            images: [...Array.from({length: 68}, (_, i) => `/img/Houches/Output/${i+1}.webp`)],
            imageCaption: {},
            alt: "Mont-Blanc"
        },
        {
            url: "/photo/rémi",
            title: "Rémi",
            previewImage: "/img/Rémi/Output/0.webp",
            images: [...Array.from({length: 13}, (_, i) => `/img/Rémi/Output/${i+1}.webp`)],
            imageCaption: {
            },
            alt: "Portrait de Rémi"
        },
        {
            url: "/photo/belgique",
            title: "Belgique",
            previewImage: "/img/Belgique/Output/0.webp",
            images : [ ...Array.from({length: 21}, (_, i) => `/img/Belgique/Output/${i+1}.webp`)
            ],
            imageCaption: {},
            alt: "Belgique"
        },
        {
            url: "/photo/animaux",
            title: "Animaux",
            previewImage: "/img/Animaux/Output/0.webp",
            images: [ ...Array.from({length: 15}, (_, i) => `/img/Animaux/Output/${i+1}.webp`)],
            imageCaption: {},
            alt: "Animaux"
        },
        {
            url: "/photo/lille",
            title: "Lille",
            previewImage: "/img/Lille/Output/0.webp",
            images: [ ...Array.from({length: 2}, (_, i) => `/img/Lille/Output/${i+1}.webp`) ],
            imageCaption: {
            },
            alt: "Ville de lille"
        },
        {
            url: "/photo/robin",
            title: "Robin",
            previewImage: "/img/Robin/Output/0.webp",
            images: [ ...Array.from({length: 18}, (_, i) => `/img/Robin/Output/${i+1}.webp`)],
            imageCaption: {
            },
            alt: "Portrait de Robin"
        },
    ]



    const router = createBrowserRouter([
        {
            path: "/",
            element: <PrimarySearchAppBar />, // Pass points to AppBar
            errorElement: <ErrorPage />,
            children: [
                {
                    path: "/",
                    element: <MainPage projects={projects} photo={photo} />,
                },
            ]
        },
        ...projects.map(project => ({
            path: project.url,
            element: <ProjectPage data={project} />,
        })),
        ...photo.map(photo => ({
            path: photo.url,
            element: <PhotoPage data={photo} />,
        })),
        // all photos
        {
            path: "/photos",
            element: <AllPhotos images={photo.reduce((acc, cur) => [...acc, ...cur.images], [])} />,
        }


    ]);

    return (
        <>
            <ThemeProvider theme={responsiveFontSizeTheme}>
                <CssBaseline enableColorScheme  />
                <RouterProvider router={router} />
            </ThemeProvider>
        </>
    );
}

export default App;
