import { CloseRounded } from "@mui/icons-material";
import { Fade, Grid, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function Projectpage({ data }) {

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        window.history.back();
    }

    useEffect(() => {
        setOpen(true);
        // force scroll to top
        window.scrollTo(0, 0);
    }, []);


    return (
        <Fade in={open} timeout={500}>
            <Slide direction="down" in={open} timeout={500}>
                <Grid container  sx={{ pl: 2, pr: 2 }}>
                    <Grid item xs={12} sx={{textAlign: "end", position: "fixed", width: "98%", zIndex: 1000, backgroundColor: "background.default"}}>
                        <IconButton onClick={handleClose} size="large" disableRipple>
                            <CloseRounded sx={{ fontSize: 50 }} />
                        </IconButton>
                    </Grid>
                    <Toolbar />
                    <Grid item xs={12}>
                        <Typography textTransform={"uppercase"} fontWeight={600} variant="h3">
                            {data?.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography sx={{ fontSize: 24 }} variant="body2" color="text.secondary">
                            {data?.projectName}
                        </Typography>
                    </Grid> 
                    <Grid item xs={12} sx={{ mt: 2  }}>
                        <Typography variant="caption">
                            {data?.tech}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}  sx={{ mt: 2  }}>
                        {data?.status}
                    </Grid>
                    <Grid item xs={6}  sx={{ mt: 2  }}>
                        {data?.content}
                    </Grid>
                    <Grid item xs={6}>
                        {data?.images?.map((image, index) => (
                            <img key={index} src={image} alt={data?.title} style={{maxWidth: "100%", height: "auto"}} />
                        ))}
                    </Grid>
                </Grid>
            </Slide>
        </Fade>
    );
}
