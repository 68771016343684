import { CloseRounded } from "@mui/icons-material";
import { AppBar, Box, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import { useEffect } from "react";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';


export default function PhotoPage({ data }) {



    let Slider = withAutoplay(AwesomeSlider);


    const handleClose = () => {
        window.history.back();
    }

    useEffect(() => {
        // force scroll to top
        window.scrollTo(0, 0);
    }, []);


    // const fetchImagesFromDirectory = async (directory) => {
    //     const extensions = ["jpg", "jpeg", "png", "gif", "webp"];
    //     const foundImages = [];
    //     let index = 1;
    //     while (true) {
    //         let found = false;
    //         for (const ext of extensions) {
    //             const testImage = `${directory}${index}.${ext}`;
    //             const imageObj = new Image();
    //             imageObj.src = testImage;
    //             const isImageFound = await new Promise((resolve) => {
    //                 imageObj.onload = () => resolve(true);
    //                 imageObj.onerror = () => resolve(false);
    //             });
    //             if (isImageFound) {
    //                 foundImages.push(testImage);
    //                 found = true;
    //                 break;
    //             }
    //         }
    //         index++;
    //         if (!found) {
    //             // console.error(`No valid image for ${directory}${index}`);
    //             break;
    //         }
    //     }
    //     setImages(foundImages);
    // };


    // useEffect(() => {
    //     fetchImagesFromDirectory(data?.imageDir);
    // }, [data?.imageDir]);

    return (
        <Grid container>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'transparent', elevation: 0, boxShadow: 'none', backgroundImage: 'none', display: { xs: 'none', lg: 'block' } }}>
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            variant="h4"
                            fontWeight={800}
                            component="div"
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                            {data?.title}
                        </Typography>
                        
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            size='large'
                            onClick={() => handleClose()}
                        >
                            <CloseRounded sx={{ fontSize: 40 }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Toolbar sx={{ display: { xs: 'none', lg: 'block' } }} />
            </Box>

            <Slider
                play={false}
                cancelOnInteraction={true}
                transitionDelay={500}
                mobileTouch={true}
                fillParent={true}
                bullets={window.innerHeight > 1000}
                style={{  height: window.innerHeight > 1000 ? '100vh' : '100vh', width: '100vw' }}
            >
                {data?.images.map((image, index) => (
                    <Grid key={index} container xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", direction: "column" }}>
                        <Grid item xs={12}>
                            <img  src={image} alt={data?.title + " - " + data?.imageCaption[index]} style={{  maxWidth: "100vw", maxHeight: data?.imageCaption[index] ? "calc(100vh - 25px)" : "100vh", objectFit: "contain" }} />
                        </Grid>
                        {data?.imageCaption[index] && (
                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                <Typography variant="caption" color="text.secondary">
                                    {data?.imageCaption[index]}
                                </Typography>
                            </Grid>
                        )}
                        
                    </Grid>
                ))}
            </Slider>

        </Grid>
    );
}
