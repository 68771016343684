import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from "@mui/material";

const TSection = ({ title, icon: Icon, children }) => (
    <Box>
        <Box display="flex" alignItems="center" sx={{ marginY: 3 }}>
            <Icon sx={{ fontSize: 30, marginRight: 1 }} color="primary" />
            <Typography
                variant="h4"
                fontWeight={600}
                textTransform="uppercase"
                color="primary"
            >
                {title}
            </Typography>
        </Box>
        {children}
        <Divider
            sx={{
                backgroundColor: "gray",
                width: "100%",
                height: "1px",
                marginTop: 3,
                marginBottom: 3,
            }}
        />
    </Box>
);

const TAccordionSection = ({ expanded, setExpanded, id, title, icon: Icon, children }) => (
    <Accordion elevation={0} sx={{ backgroundColor: "background.default" }} disableGutters expanded={expanded === id} onChange={() => id === expanded ? setExpanded(null) : setExpanded(id)}>
        <AccordionSummary
            expandIcon={expanded === id ? <RemoveRounded color="primary" /> : <AddRounded color="primary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ backgroundColor: "background.default", padding: 0 }}
        >
            <Box display="flex" alignItems="center">
                <Icon sx={{ fontSize: 30, marginRight: 1 }} color="primary" />
                <Typography
                    variant="h5"
                    fontWeight={600}
                    textTransform="uppercase"
                >
                    {title}
                </Typography>
            </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "background.default" }}>
            {children}
        </AccordionDetails>
    </Accordion>
);

const TSubsection = ({ title, details }) => (
    <Box sx={{ paddingLeft: 3 }}>
        <Typography variant="body1" fontWeight={600} sx={{ marginTop: 2 }}>
            {title}
        </Typography>
        <Typography variant="body2" fontWeight={500}>
            {details}
        </Typography>
    </Box>
);

export { TAccordionSection, TSection, TSubsection };

